define("ember-lifeline/run-task", ["exports", "@ember/debug", "@ember/runloop", "@ember/destroyable", "ember-lifeline/utils/get-task"], function (_exports, _debug, _runloop, _destroyable, _getTask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._setRegisteredTimers = _setRegisteredTimers;
  _exports.cancelTask = cancelTask;
  _exports.runTask = runTask;
  _exports.scheduleTask = scheduleTask;
  _exports.throttleTask = throttleTask;
  const NULL_TIMER_ID = -1;

  /**
   * A map of instances/timers that allows us to
   * store cancelIds for scheduled timers per instance.
   *
   * @private
   */
  let registeredTimers = new WeakMap();

  /**
   * Test use only. Allows for swapping out the WeakMap to a Map, giving
   * us the ability to detect whether the timers set is empty.
   *
   * @private
   * @param {*} mapForTesting A map used to ensure correctness when testing.
   */
  function _setRegisteredTimers(mapForTesting) {
    registeredTimers = mapForTesting;
  }

  /**
   * Registers and runs the provided task function for the provided object at the specified
   * timeout (defaulting to 0). The timer is properly canceled if the object is destroyed
   * before it is invoked.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { runTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   didInsertElement() {
   *     runTask(this, () => {
   *       console.log('This runs after 5 seconds if this component is still displayed');
   *     }, 5000)
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function runTask
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { Function | String } taskOrName a function representing the task, or string
   *                                         specifying a property representing the task,
   *                                         which is run at the provided time specified
   *                                         by timeout
   * @param { Number } [timeout=0] the time in the future to run the task
   * @public
   */
  function runTask(destroyable, taskOrName, timeout = 0) {
    if (destroyable.isDestroying) {
      return NULL_TIMER_ID;
    }
    let task = (0, _getTask.default)(destroyable, taskOrName, 'runTask');
    let timers = getTimers(destroyable);
    let cancelId = (0, _runloop.later)(() => {
      timers.delete(cancelId);
      task.call(destroyable);
    }, timeout);
    timers.add(cancelId);
    return cancelId;
  }

  /**
   * Adds the provided function to the named queue for the provided object. The timer is
   * properly canceled if the object is destroyed before it is invoked.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { scheduleTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   init() {
   *     this._super(...arguments);
   *
   *     scheduleTask(this, 'actions', () => {
   *       console.log('This runs at the end of the run loop (via the actions queue) if this component is still displayed');
   *     })
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function scheduleTask
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { String } queueName the queue to schedule the task into
   * @param { Function | String } taskOrName a function representing the task, or string
   *                                         specifying a property representing the task,
   *                                         which is run at the provided time specified
   *                                         by timeout
   * @param { ...* } args arguments to pass to the task
   * @public
   */
  function scheduleTask(destroyable, queueName, taskOrName, ...args) {
    (false && !(typeof queueName === 'string') && (0, _debug.assert)(`Called \`scheduleTask\` without a string as the first argument on ${destroyable}.`, typeof queueName === 'string'));
    (false && !(queueName !== 'afterRender') && (0, _debug.assert)(`Called \`scheduleTask\` while trying to schedule to the \`afterRender\` queue on ${destroyable}.`, queueName !== 'afterRender'));
    if (destroyable.isDestroying) {
      return NULL_TIMER_ID;
    }
    let task = (0, _getTask.default)(destroyable, taskOrName, 'scheduleTask');
    let timers = getTimers(destroyable);
    let cancelId;
    let taskWrapper = (...taskArgs) => {
      timers.delete(cancelId);
      task.call(destroyable, ...taskArgs);
    };
    cancelId = (0, _runloop.schedule)(queueName, destroyable, taskWrapper, ...args);
    timers.add(cancelId);
    return cancelId;
  }

  /**
   * Runs the function with the provided name immediately, and only once in the time window
   * specified by the spacing argument.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { throttleTask, runDisposables } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   logMe() {
   *     console.log('This will run once immediately, then only once every 300ms.');
   *   },
   *
   *   click() {
   *     throttleTask(this, 'logMe', 300);
   *   },
   *
   *   destroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function throttleTask
   * @param { IDestroyable } destroyable the instance to register the task for
   * @param { String } taskName the name of the task to throttle
   * @param { ...* } [throttleArgs] arguments to pass to the throttled method
   * @param { Number } spacing the time in the future to run the task
   * @param { Boolean } [immediate] Trigger the function on the leading instead of the trailing edge of the wait interval. Defaults to true.
   * @public
   */
  function throttleTask(destroyable, taskName, ...throttleArgs) {
    (false && !(typeof taskName === 'string') && (0, _debug.assert)(`Called \`throttleTask\` without a string as the first argument on ${destroyable}.`, typeof taskName === 'string'));
    (false && !(typeof destroyable[taskName] === 'function') && (0, _debug.assert)(`Called \`throttleTask('${taskName}')\` where '${taskName}' is not a function.`, typeof destroyable[taskName] === 'function'));
    if (destroyable.isDestroying) {
      return NULL_TIMER_ID;
    }
    const lastArgument = throttleArgs[throttleArgs.length - 1];
    const spacing = typeof lastArgument === 'boolean' ? throttleArgs[throttleArgs.length - 2] : lastArgument;
    (false && !(typeof spacing === 'number') && (0, _debug.assert)(`Called \`throttleTask\` with incorrect \`spacing\` argument. Expected Number and received \`${spacing}\``, typeof spacing === 'number'));
    let timers = getTimers(destroyable);
    let cancelId = (0, _runloop.throttle)(destroyable, taskName, ...throttleArgs);
    timers.add(cancelId);
    return cancelId;
  }

  /**
   * Cancel a previously scheduled task.
   *
   * Example:
   *
   * ```js
   * import Component from 'ember-component';
   * import { runTask, cancelTask } from 'ember-lifeline';
   *
   * export default Component.extend({
   *   didInsertElement() {
   *     this._cancelId = runTask(this, () => {
   *       console.log('This runs after 5 seconds if this component is still displayed');
   *     }, 5000)
   *   },
   *
   *   disable() {
   *     cancelTask(this, this._cancelId);
   *   },
   *
   *   willDestroy() {
   *     this._super(...arguments);
   *
   *     runDisposables(this);
   *   }
   * });
   * ```
   *
   * @function cancelTask
   * @param { IDestroyable } destroyable the entangled object that was provided with the original *Task call
   * @param { Number } cancelId the id returned from the *Task call
   * @public
   */
  function cancelTask(destroyable, cancelId) {
    let timers = getTimers(destroyable);
    timers.delete(cancelId);
    (0, _runloop.cancel)(cancelId);
  }
  function getTimersDisposable(destroyable, timers) {
    return function () {
      timers.forEach(cancelId => {
        cancelTask(destroyable, cancelId);
      });
      timers.clear();
    };
  }
  function getTimers(destroyable) {
    let timers = registeredTimers.get(destroyable);
    if (!timers) {
      timers = new Set();
      registeredTimers.set(destroyable, timers);
      (0, _destroyable.registerDestructor)(destroyable, getTimersDisposable(destroyable, timers));
    }
    return timers;
  }
});