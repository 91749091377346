define("ember-steps/components/step-manager/step", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/utils", "@ember/debug", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _utils, _debug, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isActive}}
    {{yield
      (hash
        hasNext=this.hasNext
        hasPrevious=this.hasPrevious
      )
    }}
  {{else if (has-block "inverse")}}
    {{yield to="inverse"}}
  {{/if}}
  
  */
  {
    "id": "KezsGIfJ",
    "block": "[[[41,[30,0,[\"isActive\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"hasNext\",\"hasPrevious\"],[[30,0,[\"hasNext\"]],[30,0,[\"hasPrevious\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],null]],[]]]],[\"&default\",\"&else\"],false,[\"if\",\"yield\",\"hash\",\"has-block\"]]",
    "moduleName": "ember-steps/components/step-manager/step.hbs",
    "isStrictMode": false
  });
  /**
   * ```hbs
   * <w.Step @name="first">
   *   <h1>First Step</h1>
   * </w.Step>
   * ```
   *
   * @class StepComponent
   * @yield {Hash} step Step Properties
   * @yield {boolean} step.hasPrevious Whether this step has a "previous" step
   * @yield {boolean} step.hasNext Whether this step has a "next" step
   */
  let StepComponent = _exports.default = (_class = class StepComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /** Set directly by manager through JS **/
      _initializerDefineProperty(this, "transitions", _descriptor, this);
      this.args['register-step'](this);
    }
    willDestroy(...args) {
      this.args['remove-step'](this);
      super.willDestroy(...args);
    }

    /**
     * @type {string|number|Symbol}
     * @private
     */
    get name() {
      const initialName = !(0, _utils.isNone)(this.args.name) ? this.args.name : (0, _internals.guidFor)(this);
      if (this._hasSetName) {
        (false && !(this._initialName === initialName) && (0, _debug.assert)('The `name` property should never change', this._initialName === initialName));
      }
      this._hasSetName = true;
      this._initialName = initialName;
      return initialName;
    }

    /**
     * Whether this state is currently the active one
     * @property {boolean} isActive
     * @private
     */
    get isActive() {
      return this.args.currentStep === this.name;
    }
    get hasNext() {
      return (0, _utils.isPresent)(this.transitions.pickNext(this.name));
    }
    get hasPrevious() {
      return (0, _utils.isPresent)(this.transitions.pickPrevious(this.name));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "transitions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StepComponent);
});