define("ember-bootstrap/components/bs-modal/header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let
    (ensure-safe-component (bs-default @titleComponent (component "bs-modal/header/title")))
    (component (ensure-safe-component (bs-default @closeComponent (component "bs-modal/header/close"))) onClick=@onClose)
  as |Title Close|
  }}
    <div class="modal-header" ...attributes>
      {{#if (has-block-params)}}
        {{yield
          (hash
            title=Title
            close=Close
          )
        }}
      {{else}}
        {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Title>{{@title}}</Title>
          {{/if}}
          {{#if (bs-default @closeButton true)}}
            <Close/>
          {{/if}}
        {{/if}}
        {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
          {{#if (bs-default @closeButton true)}}
            <Close/>
          {{/if}}
          {{#if (has-block)}}
            {{yield}}
          {{else}}
            <Title>{{@title}}</Title>
          {{/if}}
        {{/if}}
      {{/if}}
    </div>
  {{/let}}
  */
  {
    "id": "bJi+TVr6",
    "block": "[[[44,[[28,[37,1],[[28,[37,2],[[30,1],[50,\"bs-modal/header/title\",0,null,null]],null]],null],[50,[28,[37,1],[[28,[37,2],[[30,2],[50,\"bs-modal/header/close\",0,null,null]],null]],null],0,null,[[\"onClick\"],[[30,3]]]]],[[[1,\"  \"],[11,0],[24,0,\"modal-header\"],[17,6],[12],[1,\"\\n\"],[41,[49,[30,9]],[[[1,\"      \"],[18,9,[[28,[37,7],null,[[\"title\",\"close\"],[[30,4],[30,5]]]]]],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,7],true],null],[[[1,\"          \"],[8,[30,5],null,null,null],[1,\"\\n\"]],[]],null],[41,[48,[30,9]],[[[1,\"          \"],[18,9,null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[30,4],null,null,[[\"default\"],[[[[1,[30,8]]],[]]]]],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[4,5]]]],[\"@titleComponent\",\"@closeComponent\",\"@onClose\",\"Title\",\"Close\",\"&attrs\",\"@closeButton\",\"@title\",\"&default\"],false,[\"let\",\"ensure-safe-component\",\"bs-default\",\"component\",\"if\",\"has-block-params\",\"yield\",\"hash\",\"has-block\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/header.hbs",
    "isStrictMode": false
  });
  /**
  
   Modal header element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalHeader
   @namespace Components
   @extends Glimmer.Component
   @public
   */

  /**
   * Show a close button (x icon)
   *
   * @property closeButton
   * @type boolean
   * @default true
   * @public
   */

  /**
   * The title to display in the modal header
   *
   * @property title
   * @type string
   * @default null
   * @public
   */

  /**
   * @property titleComponent
   * @type {String}
   * @private
   */

  /**
   * @property closeComponent
   * @type {String}
   * @private
   */

  /**
   * @event onClose
   * @public
   */
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});