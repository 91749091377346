define("ember-bootstrap/components/bs-form/element/control/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember-decorators/component", "ember-bootstrap/components/bs-form/element/control", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _control, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <input
    type="checkbox"
    id={{@id}}
    disabled={{this.disabled}}
    readonly={{this.readonly}}
    aria-describedby={{this.ariaDescribedBy}}
    checked={{this.value}}
    class="{{if (macroCondition (macroGetOwnConfig "isNotBS3")) "form-check-input"}} {{if (macroCondition (macroGetOwnConfig "isNotBS3")) this.formValidationClass}}"
    ...attributes
    {{on "click" this.handleClick}}
  >
  
  
  */
  {
    "id": "NH5i04Rl",
    "block": "[[[11,\"input\"],[24,4,\"checkbox\"],[16,1,[30,1]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"readonly\",[30,0,[\"readonly\"]]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[16,\"checked\",[30,0,[\"value\"]]],[16,0,[29,[[27],\" \",[27]]]],[17,2],[4,[38,0],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[13],[1,\"\\n\\n\"]],[\"@id\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/control/checkbox.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementControlCheckbox
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlCheckbox = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementControlCheckbox extends _control.default {
    handleClick(event) {
      this.onChange(event.target.checked);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype)), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementControlCheckbox);
});