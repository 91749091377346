define("memory-scroll/components/remember-document-scroll", ["exports", "memory-scroll/components/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    targetElement() {
      return document.scrollingElement ||
      // Covers every modern browser
      document.documentElement; // Covers IE
    }
  });
});