define("memory-scroll/components/base", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    memory: (0, _service.inject)('memory-scroll'),
    didRender() {
      this._super(...arguments);
      let key = this.get('key');
      if (!key) {
        throw new Error("You must provide a key to memory-scroll like {{memory-scroll key=\"my-awesome-pane\"}}.");
      }
      if (key !== this._lastKey) {
        this.remember(this._lastKey);
        this._lastKey = key;
        this.restore(key);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this.remember(this.get('key'));
    },
    remember(key) {
      if (key) {
        let position = this.targetElement().scrollTop;
        this.get('memory')[key] = position;
      }
    },
    restore(key) {
      let position = this.get('memory')[key] || 0;
      let elt = this.targetElement();
      if (elt) {
        elt.scrollTop = position;
      }
    }
  });
});