define("ember-bootstrap/components/bs-form/element/control/textarea", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember-decorators/component", "ember-bootstrap/components/bs-form/element/control", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _object, _component2, _control, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <textarea
    id={{@id}}
    disabled={{this.disabled}}
    readonly={{this.readonly}}
    aria-describedby={{this.ariaDescribedBy}}
    value={{this.value}}
    class="form-control {{if (macroCondition (macroGetOwnConfig "isNotBS3")) this.formValidationClass}}"
    ...attributes
    {{on "change" this.handleChange}}
    {{on "input" this.handleInput}}>
  </textarea>
  */
  {
    "id": "oP+u1yqN",
    "block": "[[[11,\"textarea\"],[16,1,[30,1]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"readonly\",[30,0,[\"readonly\"]]],[16,\"aria-describedby\",[30,0,[\"ariaDescribedBy\"]]],[16,2,[30,0,[\"value\"]]],[16,0,[29,[\"form-control \",[27]]]],[17,2],[4,[38,0],[\"change\",[30,0,[\"handleChange\"]]],null],[4,[38,0],[\"input\",[30,0,[\"handleInput\"]]],null],[12],[1,\"\"],[13]],[\"@id\",\"&attrs\"],false,[\"on\"]]",
    "moduleName": "ember-bootstrap/components/bs-form/element/control/textarea.hbs",
    "isStrictMode": false
  });
  /**
  
   @class FormElementControlTextarea
   @namespace Components
   @extends Components.FormElementControl
   @private
   */
  let FormElementControlTextarea = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class FormElementControlTextarea extends _control.default {
    handleChange(event) {
      this.onChange(event.target.value);
    }
    handleInput(event) {
      this.onChange(event.target.value);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInput"), _class2.prototype)), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormElementControlTextarea);
});