define("ember-bootstrap/components/bs-dropdown/button", ["exports", "@ember/component", "@ember/template-factory", "ember-bootstrap/components/bs-button"], function (_exports, _component, _templateFactory, _bsButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    disabled={{this.__disabled}}
    type={{this.buttonType}}
    aria-expanded={{if @isOpen "true" "false"}}
    class="btn dropdown-toggle {{if @active "active"}} {{if this.block "btn-block"}} {{bs-size-class "btn" @size}} {{bs-type-class "btn" @type default=(if (macroCondition (macroGetOwnConfig "isBS3")) "default" "secondary") outline=@outline}}"
    ...attributes
    {{on "click" this.handleClick}}
    {{on "keydown" @onKeyDown}}
    {{did-insert @registerChildElement "toggle"}}
    {{will-destroy @unregisterChildElement "toggle"}}
  >
    {{#if this.icon}}<i class={{this.icon}}></i> {{/if}}{{this.text}}{{yield
      (hash
        isFulfilled=this.isFulfilled
        isPending=this.isPending
        isRejected=this.isRejected
        isSettled=this.isSettled
      )
    }}
  </button>
  
  */
  {
    "id": "WKBjqUgG",
    "block": "[[[11,\"button\"],[16,\"disabled\",[30,0,[\"__disabled\"]]],[16,4,[30,0,[\"buttonType\"]]],[16,\"aria-expanded\",[52,[30,1],\"true\",\"false\"]],[16,0,[29,[\"btn dropdown-toggle \",[52,[30,2],\"active\"],\" \",[52,[30,0,[\"block\"]],\"btn-block\"],\" \",[28,[37,1],[\"btn\",[30,3]],null],\" \",[28,[37,2],[\"btn\",[30,4]],[[\"default\",\"outline\"],[\"default\",[30,5]]]]]]],[17,6],[4,[38,3],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,3],[\"keydown\",[30,7]],null],[4,[38,4],[[30,8],\"toggle\"],null],[4,[38,5],[[30,9],\"toggle\"],null],[12],[1,\"\\n  \"],[41,[30,0,[\"icon\"]],[[[10,\"i\"],[15,0,[30,0,[\"icon\"]]],[12],[13],[1,\" \"]],[]],null],[1,[30,0,[\"text\"]]],[18,10,[[28,[37,7],null,[[\"isFulfilled\",\"isPending\",\"isRejected\",\"isSettled\"],[[30,0,[\"isFulfilled\"]],[30,0,[\"isPending\"]],[30,0,[\"isRejected\"]],[30,0,[\"isSettled\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isOpen\",\"@active\",\"@size\",\"@type\",\"@outline\",\"&attrs\",\"@onKeyDown\",\"@registerChildElement\",\"@unregisterChildElement\",\"&default\"],false,[\"if\",\"bs-size-class\",\"bs-type-class\",\"on\",\"did-insert\",\"will-destroy\",\"yield\",\"hash\"]]",
    "moduleName": "ember-bootstrap/components/bs-dropdown/button.hbs",
    "isStrictMode": false
  });
  /**
   Button component with that can act as a dropdown toggler.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownButton
   @namespace Components
   @extends Components.Button
   @public
   */
  class DropdownButton extends _bsButton.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, '__ember-bootstrap_subclass', true);
    }
  }
  _exports.default = DropdownButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownButton);
});