define("ember-bootstrap/components/bs-collapse", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@ember/utils", "@ember/runloop", "@ember/string", "ember-bootstrap/utils/transition-end", "ember-bootstrap/utils/deprecate-subclassing", "ember-ref-bucket", "ember-bootstrap/utils/decorators/arg", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _utils, _runloop, _string, _transitionEnd, _deprecateSubclassing, _emberRefBucket, _arg, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="{{if this.collapse "collapse"}} {{if this.transitioning "collapsing"}} {{if this.showContent (if (macroCondition (macroGetOwnConfig "isNotBS3")) "show" "in")}}"
    ...attributes
    {{create-ref "mainNode"}}
    {{style this.cssStyle}}
    {{did-update this._onCollapsedChange this.collapsed}}
    {{did-update this._updateCollapsedSize this.collapsedSize}}
    {{did-update this._updateExpandedSize this.expandedSize}}
  >
    {{yield}}
  </div>
  
  */
  {
    "id": "lW7lH7d+",
    "block": "[[[11,0],[16,0,[29,[[52,[30,0,[\"collapse\"]],\"collapse\"],\" \",[52,[30,0,[\"transitioning\"]],\"collapsing\"],\" \",[52,[30,0,[\"showContent\"]],\"in\"]]]],[17,1],[4,[38,1],[\"mainNode\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]],[4,[38,2],[[30,0,[\"cssStyle\"]]],null],[4,[38,3],[[30,0,[\"_onCollapsedChange\"]],[30,0,[\"collapsed\"]]],null],[4,[38,3],[[30,0,[\"_updateCollapsedSize\"]],[30,0,[\"collapsedSize\"]]],null],[4,[38,3],[[30,0,[\"_updateExpandedSize\"]],[30,0,[\"expandedSize\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"create-ref\",\"style\",\"did-update\",\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-collapse.hbs",
    "isStrictMode": false
  });
  /**
    An Ember component that mimics the behaviour of [Bootstrap's collapse.js plugin](http://getbootstrap.com/javascript/#collapse)
  
    ### Usage
  
    ```hbs
    <BsCollapse @collapsed={{this.collapsed}}>
      <div class="well">
        <h2>Collapse</h2>
        <p>This is collapsible content</p>
      </div>
    </BsCollapse>
    ```
  
    *Note that only invoking the component in a template as shown above is considered part of its public API. Extending from it (subclassing) is generally not supported, and may break at any time.*
  
    @class Collapse
    @namespace Components
    @extends Glimmer.Component
    @public
  */
  let Collapse = _exports.default = (_dec = (0, _emberRefBucket.ref)('mainNode'), (0, _deprecateSubclassing.default)(_class = (_class2 = class Collapse extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * @property _element
       * @type null | HTMLElement
       * @private
       */
      _initializerDefineProperty(this, "_element", _descriptor, this);
      /**
       * Collapsed/expanded state
       *
       * @property collapsed
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "collapsed", _descriptor2, this);
      /**
       * True if this item is expanded
       *
       * @property active
       * @private
       */
      _initializerDefineProperty(this, "active", _descriptor3, this);
      /**
       * true if the component is currently transitioning
       *
       * @property transitioning
       * @type boolean
       * @private
       */
      _initializerDefineProperty(this, "transitioning", _descriptor4, this);
      /**
       * The size of the element when collapsed. Defaults to 0.
       *
       * @property collapsedSize
       * @type number
       * @default 0
       * @public
       */
      _initializerDefineProperty(this, "collapsedSize", _descriptor5, this);
      /**
       * The size of the element when expanded. When null the value is calculated automatically to fit the containing elements.
       *
       * @property expandedSize
       * @type number
       * @default null
       * @public
       */
      _initializerDefineProperty(this, "expandedSize", _descriptor6, this);
      /**
       * Usually the size (height) of the element is only set while transitioning, and reseted afterwards. Set to true to always set a size.
       *
       * @property resetSizeWhenNotCollapsing
       * @type boolean
       * @default true
       * @private
       */
      _defineProperty(this, "resetSizeWhenNotCollapsing", true);
      /**
       * The direction (height/width) of the collapse animation.
       * When setting this to 'width' you should also define custom CSS transitions for the width property, as the Bootstrap
       * CSS does only support collapsible elements for the height direction.
       *
       * @property collapseDimension
       * @type string
       * @default 'height'
       * @public
       */
      _initializerDefineProperty(this, "collapseDimension", _descriptor7, this);
      /**
       * The duration of the fade transition
       *
       * @property transitionDuration
       * @type number
       * @default 350
       * @public
       */
      _initializerDefineProperty(this, "transitionDuration", _descriptor8, this);
      _initializerDefineProperty(this, "collapseSize", _descriptor9, this);
    }
    get collapse() {
      return !this.transitioning;
    }
    get showContent() {
      return this.collapse && this.active;
    }
    /**
     * Calculates a hash for style attribute.
     */
    get cssStyle() {
      if ((0, _utils.isNone)(this.collapseSize)) {
        return {};
      }
      return {
        [this.collapseDimension]: `${this.collapseSize}px`
      };
    }
    /**
     * The action to be sent when the element is about to be hidden.
     *
     * @event onHide
     * @public
     */

    /**
     * The action to be sent after the element has been completely hidden (including the CSS transition).
     *
     * @event onHidden
     * @public
     */

    /**
     * The action to be sent when the element is about to be shown.
     *
     * @event onShow
     * @public
     */

    /**
     * The action to be sent after the element has been completely shown (including the CSS transition).
     *
     * @event onShown
     * @public
     */

    /**
     * Triggers the show transition
     *
     * @method show
     * @protected
     */
    show() {
      this.args.onShow?.();
      this.transitioning = true;
      this.active = true;
      this.collapseSize = this.collapsedSize;
      (0, _transitionEnd.default)(this._element, this.transitionDuration).then(() => {
        if (this.isDestroyed) {
          return;
        }
        this.transitioning = false;
        if (this.resetSizeWhenNotCollapsing) {
          this.collapseSize = null;
        }
        this.args.onShown?.();
      });
      (0, _runloop.next)(this, function () {
        if (!this.isDestroyed) {
          this.collapseSize = this.getExpandedSize('show');
        }
      });
    }

    /**
     * Get the size of the element when expanded
     *
     * @method getExpandedSize
     * @param action
     * @return {Number}
     * @private
     */
    getExpandedSize(action) {
      let expandedSize = this.expandedSize;
      if (expandedSize != null) {
        return expandedSize;
      }
      let collapseElement = this._element;
      let prefix = action === 'show' ? 'scroll' : 'offset';
      let measureProperty = (0, _string.camelize)(`${prefix}-${this.collapseDimension}`);
      return collapseElement[measureProperty];
    }

    /**
     * Triggers the hide transition
     *
     * @method hide
     * @protected
     */
    hide() {
      this.args.onHide?.();
      this.transitioning = true;
      this.active = false;
      this.collapseSize = this.getExpandedSize('hide');
      (0, _transitionEnd.default)(this._element, this.transitionDuration).then(() => {
        if (this.isDestroyed) {
          return;
        }
        this.transitioning = false;
        if (this.resetSizeWhenNotCollapsing) {
          this.collapseSize = null;
        }
        this.args.onHidden?.();
      });
      (0, _runloop.next)(this, function () {
        if (!this.isDestroyed) {
          this.collapseSize = this.collapsedSize;
        }
      });
    }
    _onCollapsedChange() {
      let collapsed = this.collapsed;
      let active = this.active;
      if (collapsed !== active) {
        return;
      }
      if (collapsed === false) {
        this.show();
      } else {
        this.hide();
      }
    }
    _updateCollapsedSize() {
      if (!this.resetSizeWhenNotCollapsing && this.collapsed && !this.collapsing) {
        this.collapseSize = this.collapsedSize;
      }
    }
    _updateExpandedSize() {
      if (!this.resetSizeWhenNotCollapsing && !this.collapsed && !this.collapsing) {
        this.collapseSize = this.expandedSize;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "_element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "collapsed", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return !this.collapsed;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "transitioning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "collapsedSize", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "expandedSize", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "collapseDimension", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'height';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "transitionDuration", [_arg.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 350;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "collapseSize", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "_onCollapsedChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCollapsedChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_updateCollapsedSize", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_updateCollapsedSize"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_updateExpandedSize", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_updateExpandedSize"), _class2.prototype)), _class2)) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Collapse);
});