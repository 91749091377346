define("ember-bootstrap/components/bs-dropdown/menu", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "@ember/runloop", "ember-bootstrap/utils/dom", "ember-bootstrap/utils/default-decorator", "ember-bootstrap/utils/deprecate-subclassing", "ember-ref-bucket"], function (_exports, _component, _templateFactory, _component2, _object, _runloop, _dom, _defaultDecorator, _deprecateSubclassing, _emberRefBucket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this._isOpen}}
    {{#if (macroCondition (macroGetOwnConfig "isNotBS3"))}}
      <EmberPopper
        class="dropdown-menu {{this.alignClass}} {{if this.isOpen "show"}} {{@class}}"
        @placement={{this.popperPlacement}}
        @popperTarget={{this.toggleElement}}
        @renderInPlace={{this._renderInPlace}}
        @popperContainer={{this.destinationElement}}
        @modifiers={{this.popperModifiers}}
        @onCreate={{this.setFocus}}
        tabindex="-1"
        ...attributes
        {{did-insert @registerChildElement "menu"}}
        {{will-destroy @unregisterChildElement "menu"}}
        {{create-ref "menuElement"}}
      >
        {{yield
          (hash
            item=(ensure-safe-component (bs-default @itemComponent (component "bs-dropdown/menu/item")))
            link-to=(ensure-safe-component (bs-default @linkToComponent (component "bs-link-to" class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "dropdown-item"))))
            linkTo=(ensure-safe-component (bs-default @linkToComponent (component "bs-link-to" class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "dropdown-item"))))
            divider=(ensure-safe-component (bs-default @dividerComponent (component "bs-dropdown/menu/divider")))
          )
        }}
      </EmberPopper>
    {{/if}}
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
      <EmberPopper
        class="ember-bootstrap-dropdown-bs3-popper"
        @placement={{this.popperPlacement}}
        @popperTarget={{this.toggleElement}}
        @renderInPlace={{this._renderInPlace}}
        @popperContainer={{this.destinationElement}}
        @modifiers={{this.popperModifiers}}
        @onCreate={{this.setFocus}}
      >
        <ul
          class="dropdown-menu {{this.alignClass}} {{if this.isOpen "show"}} {{@class}}"
          role={{this.ariaRole}}
          tabindex="-1"
          ...attributes
          {{did-insert @registerChildElement "menu"}}
          {{will-destroy @unregisterChildElement "menu"}}
          {{create-ref "menuElement"}}
        >
          {{yield
            (hash
              item=(ensure-safe-component (bs-default @itemComponent (component "bs-dropdown/menu/item")))
              link-to=(ensure-safe-component (bs-default @linkToComponent (component "bs-link-to" class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "dropdown-item"))))
              linkTo=(ensure-safe-component (bs-default @linkToComponent (component "bs-link-to" class=(if (macroCondition (macroGetOwnConfig "isNotBS3")) "dropdown-item"))))
              divider=(ensure-safe-component (bs-default @dividerComponent (component "bs-dropdown/menu/divider")))
            )
          }}
        </ul>
      </EmberPopper>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "Orf5fFnN",
    "block": "[[[41,[30,0,[\"_isOpen\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"ember-bootstrap-dropdown-bs3-popper\"]],[[\"@placement\",\"@popperTarget\",\"@renderInPlace\",\"@popperContainer\",\"@modifiers\",\"@onCreate\"],[[30,0,[\"popperPlacement\"]],[30,0,[\"toggleElement\"]],[30,0,[\"_renderInPlace\"]],[30,0,[\"destinationElement\"]],[30,0,[\"popperModifiers\"]],[30,0,[\"setFocus\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,\"ul\"],[16,0,[29,[\"dropdown-menu \",[30,0,[\"alignClass\"]],\" \",[52,[30,0,[\"isOpen\"]],\"show\"],\" \",[30,1]]]],[16,\"role\",[30,0,[\"ariaRole\"]]],[24,\"tabindex\",\"-1\"],[17,2],[4,[38,2],[[30,3],\"menu\"],null],[4,[38,3],[[30,4],\"menu\"],null],[4,[38,4],[\"menuElement\"],[[\"debugName\",\"bucket\"],[\"create-ref\",[30,0]]]],[12],[1,\"\\n        \"],[18,8,[[28,[37,6],null,[[\"item\",\"link-to\",\"linkTo\",\"divider\"],[[28,[37,7],[[28,[37,8],[[30,5],[50,\"bs-dropdown/menu/item\",0,null,null]],null]],null],[28,[37,7],[[28,[37,8],[[30,6],[50,\"bs-link-to\",0,null,[[\"class\"],[[27]]]]],null]],null],[28,[37,7],[[28,[37,8],[[30,6],[50,\"bs-link-to\",0,null,[[\"class\"],[[27]]]]],null]],null],[28,[37,7],[[28,[37,8],[[30,7],[50,\"bs-dropdown/menu/divider\",0,null,null]],null]],null]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@class\",\"&attrs\",\"@registerChildElement\",\"@unregisterChildElement\",\"@itemComponent\",\"@linkToComponent\",\"@dividerComponent\",\"&default\"],false,[\"if\",\"ember-popper\",\"did-insert\",\"will-destroy\",\"create-ref\",\"yield\",\"hash\",\"ensure-safe-component\",\"bs-default\",\"component\"]]",
    "moduleName": "ember-bootstrap/components/bs-dropdown/menu.hbs",
    "isStrictMode": false
  });
  /**
   Component for the dropdown menu.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenu
   @namespace Components
   @extends Ember.Component
   @public
   */
  let DropdownMenu = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _emberRefBucket.ref)('menuElement'), _dec3 = (0, _object.computed)('destinationElement', 'renderInPlace'), _dec4 = (0, _object.computed)('align'), _dec5 = (0, _object.computed)('direction', 'align'), _dec6 = (0, _object.computed)('inNav', 'flip'), _dec(_class = (0, _deprecateSubclassing.default)(_class = (_class2 = class DropdownMenu extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * @property _element
       * @type null | HTMLElement
       * @private
       */
      _initializerDefineProperty(this, "menuElement", _descriptor, this);
      /**
       * @property ariaRole
       * @default menu
       * @type string
       * @protected
       */
      _defineProperty(this, "ariaRole", 'menu');
      /**
       * Alignment of the menu, either "left" or "right"
       *
       * @property align
       * @type string
       * @default left
       * @public
       */
      _initializerDefineProperty(this, "align", _descriptor2, this);
      /**
       * @property direction
       * @default 'down'
       * @type string
       * @private
       */
      _initializerDefineProperty(this, "direction", _descriptor3, this);
      /**
       * @property inNav
       * @type {boolean}
       * @private
       */
      _defineProperty(this, "inNav", false);
      /**
       * By default the menu is rendered in the same place as the dropdown. If you experience clipping
       * issues, you can set this to false to render the menu in a wormhole at the top of the DOM.
       *
       * @property renderInPlace
       * @type boolean
       * @default true
       * @public
       */
      _initializerDefineProperty(this, "renderInPlace", _descriptor4, this);
      _defineProperty(this, "_isOpen", false);
      _defineProperty(this, "flip", true);
      _defineProperty(this, "_popperApi", null);
    }
    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    get _renderInPlace() {
      return this.renderInPlace || !this.destinationElement;
    }

    /**
     * The wormhole destinationElement
     *
     * @property destinationElement
     * @type object
     * @readonly
     * @private
     */
    get destinationElement() {
      return (0, _dom.getDestinationElement)(this);
    }
    get alignClass() {
      return this.align !== 'left' ? `dropdown-menu-${this.align}` : undefined;
    }
    get isOpen() {
      return false;
    }
    set isOpen(value) {
      // delay removing the menu from DOM to allow (delegated Ember) event to fire for the menu's children
      // Fixes https://github.com/kaliber5/ember-bootstrap/issues/660
      (0, _runloop.next)(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.set('_isOpen', value);
      });
      return value;
    }
    get popperPlacement() {
      let placement = 'bottom-start';
      let {
        direction,
        align
      } = this;
      if (direction === 'up') {
        placement = 'top-start';
        if (align === 'right') {
          placement = 'top-end';
        }
      } else if (direction === 'left') {
        placement = 'left-start';
      } else if (direction === 'right') {
        placement = 'right-start';
      } else if (align === 'right') {
        placement = 'bottom-end';
      }
      return placement;
    }
    setFocus() {
      // when the dropdown menu is rendered in place, focus can stay on the toggle element
      if (this._renderInPlace) {
        return;
      }
      if (this.menuElement) {
        this.menuElement.focus();
      }
    }
    get popperModifiers() {
      return {
        // @todo add offset config
        applyStyle: {
          enabled: !this.inNav
        },
        flip: {
          enabled: this.flip
        }
      };
    }

    /**
     * @property itemComponent
     * @type {String}
     * @private
     */

    /**
     * @property linkToComponent
     * @type {String}
     * @private
     */

    /**
     * @property dividerComponent
     * @type {String}
     * @private
     */
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "menuElement", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "align", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'left';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "direction", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'down';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "_renderInPlace", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_renderInPlace"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "alignClass", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "alignClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isOpen", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "isOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperPlacement", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "popperPlacement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setFocus", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperModifiers", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "popperModifiers"), _class2.prototype)), _class2)) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownMenu);
});