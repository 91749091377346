define("ember-steps/-private/state-machine/linear", ["exports", "ember-steps/-private/state-machine/-base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Keeps track of the order of the steps in the step manager, as well as
   * the current step.
   *
   * @class CircularStateMachine
   * @extends BaseStateMachine
   * @private
   * @hide
   */
  class LinearStateMachine extends _base.default {
    pickNext(currentStep = this.currentStep) {
      const currentIndex = this.nodeArray.map(node => node.name).indexOf(currentStep);
      const currentNode = this.nodeArray[currentIndex + 1];
      if (currentNode) {
        return currentNode.name;
      }
      return undefined;
    }
    pickPrevious(currentStep = this.currentStep) {
      const currentIndex = this.nodeArray.map(node => node.name).indexOf(currentStep);
      const currentNode = this.nodeArray[currentIndex - 1];
      if (currentNode) {
        return currentNode.name;
      }
      return undefined;
    }
  }
  _exports.default = LinearStateMachine;
});