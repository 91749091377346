define("ember-steps/helpers/validate-transition", ["exports", "@ember/component/helper", "rsvp"], function (_exports, _helper, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.validateTransition = validateTransition;
  /** @hide */
  function validateTransition([transition], {
    with: validator
  }) {
    return function () {
      return new _rsvp.Promise(resolve => {
        validator(resolve);
      }).then(() => {
        transition();
      });
    };
  }
  var _default = _exports.default = (0, _helper.helper)(validateTransition);
});