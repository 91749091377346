define("ember-model-select/components/model-select/options", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul role="listbox" ...attributes>
    <li>
      <PowerSelect::Options
        @loadingMessage={{@loadingMessage}}
        @select={{@select}}
        @options={{@options}}
        @groupIndex={{@groupIndex}}
        @optionsComponent={{@optionsComponent}}
        @extra={{@extra}}
        @highlightOnHover={{@highlightOnHover}}
        @groupComponent={{@groupComponent}}
        as |option select|
      >
        {{yield option select}}
      </PowerSelect::Options>
    </li>
  
    {{#if this.showLoader}}
      <li>
        <InfinityLoader
          @infinityModel={{@infiniteModel}}
          @hideOnInfinity={{true}}
          @scrollable={{concat "#ember-basic-dropdown-content-" @select.uniqueId}}
        >
          <ModelSelect::Spinner />
        </InfinityLoader>
      </li>
    {{/if}}
  </ul>
  
  */
  {
    "id": "f3FDBBdx",
    "block": "[[[11,\"ul\"],[24,\"role\",\"listbox\"],[17,1],[12],[1,\"\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@loadingMessage\",\"@select\",\"@options\",\"@groupIndex\",\"@optionsComponent\",\"@extra\",\"@highlightOnHover\",\"@groupComponent\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]],[[\"default\"],[[[[1,\"\\n      \"],[18,13,[[30,10],[30,11]]],[1,\"\\n    \"]],[10,11]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showLoader\"]],[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@infinityModel\",\"@hideOnInfinity\",\"@scrollable\"],[[30,12],true,[28,[37,4],[\"#ember-basic-dropdown-content-\",[30,3,[\"uniqueId\"]]],null]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,null,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@loadingMessage\",\"@select\",\"@options\",\"@groupIndex\",\"@optionsComponent\",\"@extra\",\"@highlightOnHover\",\"@groupComponent\",\"option\",\"select\",\"@infiniteModel\",\"&default\"],false,[\"power-select/options\",\"yield\",\"if\",\"infinity-loader\",\"concat\",\"model-select/spinner\"]]",
    "moduleName": "ember-model-select/components/model-select/options.hbs",
    "isStrictMode": false
  });
  // eslint-disable-next-line ember/no-computed-properties-in-native-classes
  let OptionsComponent = _exports.default = (_dec = (0, _object.computed)('args.{infiniteScroll,infiniteModel,select.loading}'), (_class = class OptionsComponent extends _component2.default {
    get showLoader() {
      return this.args.infiniteScroll && this.args.infiniteModel && !this.args.select.loading;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "showLoader", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "showLoader"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OptionsComponent);
});