define("ember-model-select/components/model-select/spinner", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg
    class="ember-model-select__spinner"
    width="25"
    height="25"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd; clip-rule:evenodd; fill:none;"
  >
    <circle cx="19" cy="19" r="17" stroke-width="3.9px"/>
    <path d="M36,19c0,-9.388 -7.612,-17 -17,-17" stroke-width="3.9px"/>
  </svg>
  */
  {
    "id": "X4ikK01M",
    "block": "[[[10,\"svg\"],[14,0,\"ember-model-select__spinner\"],[14,\"width\",\"25\"],[14,\"height\",\"25\"],[14,\"viewBox\",\"0 0 38 38\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,5,\"fill-rule:evenodd; clip-rule:evenodd; fill:none;\"],[12],[1,\"\\n  \"],[10,\"circle\"],[14,\"cx\",\"19\"],[14,\"cy\",\"19\"],[14,\"r\",\"17\"],[14,\"stroke-width\",\"3.9px\"],[12],[13],[1,\"\\n  \"],[10,\"path\"],[14,\"d\",\"M36,19c0,-9.388 -7.612,-17 -17,-17\"],[14,\"stroke-width\",\"3.9px\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "ember-model-select/components/model-select/spinner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});