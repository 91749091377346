define("ember-cli-active-link-wrapper/components/active-link", ["exports", "@ember/component", "ember-cli-active-link-wrapper/mixins/active-link"], function (_exports, _component, _activeLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_activeLink.default, {
    tagName: 'li'
  });
});