define("ember-model-select/utils/get-config-option", ["exports", "ember-get-config", "@ember/object"], function (_exports, _emberGetConfig, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getConfigOption;
  /**
   *  Get's the passed configuration option from the `ember-model-select`
   *  environment key or the passed default if the config option is not set.
   *
   * @function getConfigOption
   * @param {String} key
   * @param {*} defaultValue
   * @return {*} The config option or the default value
   * @private
   * @hide
   */
  function getConfigOption(key, defaultValue) {
    let value = (0, _object.get)(_emberGetConfig.default, `ember-model-select.${key}`);
    if (value !== undefined) {
      return value;
    }
    return defaultValue;
  }
});