define("ember-cli-active-link-wrapper/mixins/active-link", ["exports", "@ember/object/mixin", "@ember/array", "@ember/object/evented", "@ember/runloop", "@ember/application", "@ember/object", "@ember/utils"], function (_exports, _mixin, _array, _evented, _runloop, _application, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // these are not currently editable in Ember
  const transitioningInClass = 'ember-transitioning-in';
  const transitioningOutClass = 'ember-transitioning-out';
  var _default = _exports.default = _mixin.default.create({
    classNameBindings: ['_active', '_disabled', '_transitioningIn', '_transitioningOut'],
    linkSelector: 'a.ember-view',
    init() {
      this._super(...arguments);
      this.set('childLinkViews', (0, _array.A)([]));
    },
    buildChildLinkViews: (0, _evented.on)('didInsertElement', function () {
      (0, _runloop.scheduleOnce)('afterRender', this, function () {
        let childLinkSelector = this.get('linkSelector');
        let childLinkElements = this.element.querySelectorAll(childLinkSelector);
        let viewRegistry = (0, _application.getOwner)(this).lookup('-view-registry:main');
        let childLinkViews = Array.prototype.map.call(childLinkElements, view => viewRegistry[view.id]);
        this.set('childLinkViews', (0, _array.A)(childLinkViews));
      });
    }),
    _transitioningIn: (0, _object.computed)('childLinkViews.@each.transitioningIn', function () {
      if (this.get('childLinkViews').isAny('transitioningIn')) {
        return transitioningInClass;
      }
    }),
    _transitioningOut: (0, _object.computed)('childLinkViews.@each.transitioningOut', function () {
      if (this.get('childLinkViews').isAny('transitioningOut')) {
        return transitioningOutClass;
      }
    }),
    hasActiveLinks: (0, _object.computed)('childLinkViews.@each.active', function () {
      return this.get('childLinkViews').isAny('active');
    }),
    activeClass: (0, _object.computed)('childLinkViews.@each.active', function () {
      let activeLink = this.get('childLinkViews').findBy('active');
      return activeLink ? activeLink.get('active') : 'active';
    }),
    _active: (0, _object.computed)('hasActiveLinks', 'activeClass', function () {
      return this.get('hasActiveLinks') ? this.get('activeClass') : false;
    }),
    allLinksDisabled: (0, _object.computed)('childLinkViews.@each.disabled', function () {
      return !(0, _utils.isEmpty)(this.get('childLinkViews')) && this.get('childLinkViews').isEvery('disabled');
    }),
    disabledClass: (0, _object.computed)('childLinkViews.@each.disabled', function () {
      let disabledLink = this.get('childLinkViews').findBy('disabled');
      return disabledLink ? disabledLink.get('disabled') : 'disabled';
    }),
    _disabled: (0, _object.computed)('allLinksDisabled', 'disabledClass', function () {
      return this.get('allLinksDisabled') ? this.get('disabledClass') : false;
    })
  });
});