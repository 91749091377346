define("ember-bootstrap/components/bs-dropdown/menu/item", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "ember-bootstrap/utils/deprecate-subclassing"], function (_exports, _component, _templateFactory, _component2, _deprecateSubclassing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (macroCondition (macroGetOwnConfig "isBS3"))}}
    <li>
      {{yield}}
    </li>
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "xXFfJdHR",
    "block": "[[[1,\"  \"],[10,\"li\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-dropdown/menu/item.hbs",
    "isStrictMode": false
  });
  /**
   Component for a dropdown menu item.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenuItem
   @namespace Components
   @extends Ember.Component
   @public
   */
  let DropdownMenuItem = _exports.default = (_dec = (0, _component2.tagName)(''), _dec(_class = (0, _deprecateSubclassing.default)(_class = class DropdownMenuItem extends _component.default {}) || _class) || _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownMenuItem);
});